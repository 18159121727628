/**
*
*/

$sass: #c76494;
$html: #e96228;
$js: #efd81d;
$python_b: #f0d14a;
$python_f: #3470a4;
$ruby: #cc342d;
$css: #2965f1;
$cs: #3a0091;
